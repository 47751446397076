* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  background-color: #F0F0F0;
  overflow-x: hidden; /* Prevents horizontal scroll */
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensures the app covers the full height */
  color: #333;
}

.App-nav {
  display: flex;
  justify-content: center;
  padding: 20px;
  background-color: #000746;
}

.App-logo {
  width: 200px;
  height: auto; 
}

.App-main {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.App-hero {
  position: relative;
  text-align: center;
  padding: 15vh 20px; 
  background-color: #000746;
  color: white;
  overflow: hidden; /* Ensures the canvas doesn't overflow */
}

canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


.App-services {
  flex: 1; 
  background: #F0F0F0;
  padding: 50px 20px;
  text-align: center;
}

.service-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 0;
}

.service {
  width: 100%;
  margin: 10px 0;
  padding: 20px;
  background-color: white;
  border: 1px solid #DDD;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.service h3 {
  color: #000746;
}

@media (min-width: 600px) {
  .service {
    width: 48%;
  }
}

@media (min-width: 1024px) {
  .service {
    width: 30%;
  }
}

.App-footer {
  background-color: #000746;
  color: white;
  text-align: center;
  padding: 20px;
  width: 100%;
}

.App-footer a {
  color: #f49265;
  text-decoration: none;
}